import { ROUTER } from 'app/constants';
import classNames from 'classnames';
import { PanelMenu } from 'primereact/panelmenu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';

const renderTemplateItem = (item, options) => {
    return (
        <Link className={`item-menu d-flex align-items-center py-1 ${options.className} ${item.className}`} onClick={options.onClick}>
            <div className={`w-100 d-flex align-items-center justify-content-between menu-label text-truncate ${options.labelClassName}`}>
                <div className='sub-menu py-2 fs-lg text-white text-sidebar'> {item.label}</div>
            </div>
        </Link>
    );
};
const RenderSidebarTemplate = function () {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    /**
     * @type {import('primereact').MenuItem[]}
     */
    const list = [
        {
            className: location.pathname.includes(generatePath(ROUTER.USER_MANAGEMENT)) ? 'is-active' : '',
            id: 'user',
            key: 'user',
            label: t('SIDEBAR_LABEL_USER_MANAGEMENT'),
            link: ROUTER.USER_MANAGEMENT,
            command: () => navigate(generatePath(ROUTER.USER_MANAGEMENT)),
            template: renderTemplateItem
        },
        {
            className: location.pathname.includes(generatePath(ROUTER.ROOM)) ? 'is-active' : '',
            id: 'room',
            key: 'room',
            label: t('LABEL_ROOM'),
            link: ROUTER.ROOM,
            command: () => navigate(generatePath(ROUTER.ROOM)),
            template: renderTemplateItem
        },
        {
            id: 'booking',
            key: 'booking',
            className: location.pathname.includes(generatePath(ROUTER.BOOKING)) ? 'is-active' : '',
            label: t('LABEL_BOOKING'),
            link: ROUTER.BOOKING,
            command: () => navigate(generatePath(ROUTER.BOOKING)),
            template: renderTemplateItem
        },
        {
            id: 'blog',
            key: 'blog',
            className: location.pathname.includes(generatePath(ROUTER.BLOG)) ? 'is-active' : '',
            label: t('LABEL_BLOGS'),
            link: ROUTER.BLOG,
            command: () => navigate(generatePath(ROUTER.BLOG)),
            template: renderTemplateItem
        },
        {
            id: 'tag',
            key: 'tag',
            className: location.pathname.includes(generatePath(ROUTER.TAG)) ? 'is-active' : '',
            label: t('LABEL_TAG'),
            link: ROUTER.TAG,
            command: () => navigate(generatePath(ROUTER.TAG)),
            template: renderTemplateItem
        },
        {
            id: 'category',
            key: 'category',
            className: location.pathname.includes(generatePath(ROUTER.CATEGORY)) ? 'is-active' : '',
            label: t('LABEL_CATEGORY'),
            link: ROUTER.CATEGORY,
            command: () => navigate(generatePath(ROUTER.CATEGORY)),
            template: renderTemplateItem
        },

        {
            id: 'carousel',
            key: 'carousel',
            className: location.pathname.includes(generatePath(ROUTER.CAROUSEL)) ? 'is-active' : '',
            label: t('LABEL_CAROUSEL'),
            link: ROUTER.CAROUSEL,
            command: () => navigate(generatePath(ROUTER.CAROUSEL)),
            template: renderTemplateItem
        },
        {
            id: 'gallery',
            key: 'gallery',
            className: location.pathname.includes(generatePath(ROUTER.GALLERY)) ? 'is-active' : '',
            label: t('LABEL_GALLERY'),
            link: ROUTER.GALLERY,
            command: () => navigate(generatePath(ROUTER.GALLERY)),
            template: renderTemplateItem
        },
        {
            id: 'contact',
            key: 'contact',
            className: location.pathname.includes(generatePath(ROUTER.CONTACT)) ? 'is-active' : '',
            label: t('LABEL_CONTACT'),
            link: ROUTER.CONTACT,
            command: () => navigate(generatePath(ROUTER.CONTACT)),
            template: renderTemplateItem
        },
        {
            id: 'gym',
            key: 'gym',
            className: location.pathname.includes(generatePath(ROUTER.GYM)) ? 'is-active' : '',
            label: 'Gym & Fitness',
            link: ROUTER.GYM,
            command: () => navigate(generatePath(ROUTER.GYM)),
            template: renderTemplateItem
        },
        {
            id: 'swimming',
            key: 'swimming',
            className: location.pathname.includes(generatePath(ROUTER.SWIMMING)) ? 'is-active' : '',
            label: 'Swimming Pool',
            link: ROUTER.SWIMMING,
            command: () => navigate(generatePath(ROUTER.SWIMMING)),
            template: renderTemplateItem
        },
        {
            id: 'spa',
            key: 'spa',
            link: ROUTER.SPA,
            className: location.pathname.includes(generatePath(ROUTER.SPA)) ? 'is-active' : '',
            label: 'Spa & Massage',
            command: () => navigate(generatePath(ROUTER.SPA)),
            template: renderTemplateItem
        },
        {
            id: 'restaurant',
            key: 'restaurant',
            className: location.pathname.includes(generatePath(ROUTER.RESTAURANT)) ? 'is-active' : '',
            label: 'Restaurant',
            link: ROUTER.RESTAURANT,
            command: () => navigate(generatePath(ROUTER.RESTAURANT)),
            template: renderTemplateItem
        },
        {
            id: 'cabanon',
            key: 'cabanon',
            link: ROUTER.CABANON_RESTAURANT,
            className: location.pathname.includes(generatePath(ROUTER.CABANON_RESTAURANT)) ? 'is-active' : '',
            label: 'Cabanon Restaurant',
            command: () => navigate(generatePath(ROUTER.CABANON_RESTAURANT)),
            template: renderTemplateItem
        },
        {
            id: 'offers',
            key: 'offers',
            className: location.pathname.includes(generatePath(ROUTER.OFFERS)) ? 'is-active' : '',
            label: 'Special Offers',
            link: ROUTER.OFFERS,
            command: () => navigate(generatePath(ROUTER.OFFERS)),
            template: renderTemplateItem
        },
        {
            id: 'utilities',
            key: 'utilities',
            className: location.pathname.includes(generatePath(ROUTER.UTILITIES_ROOM)) ? 'is-active' : '',
            label: t('LABEL_UTILITIES_ROOM'),
            link: ROUTER.UTILITIES_ROOM,
            command: () => navigate(generatePath(ROUTER.UTILITIES_ROOM)),
            template: renderTemplateItem
        },
        {
            id: 'customer',
            key: 'customer',
            label: t('LABEL_CUSTOMER_REVIEW'),
            className: location.pathname.includes(generatePath(ROUTER.CUSTOMER_REVIEW)) ? 'is-active' : '',
            link: ROUTER.CUSTOMER_REVIEW,
            command: () => navigate(generatePath(ROUTER.CUSTOMER_REVIEW)),
            template: renderTemplateItem
        }
    ];
    return list;
};
export default function SideBar() {
    const toggleSidebar = useSelector((states) => states.sidebar.toggleSidebar);
    const [toggleSidebarItem, setToggleSidebarItem] = useState({
        category: true
    });

    const styleClass = classNames(' sidebar d-flex flex-column', {
        'sidebar-collapse': toggleSidebar
    });
    const items = RenderSidebarTemplate(toggleSidebar, toggleSidebarItem, setToggleSidebarItem);

    return (
        <div className={styleClass}>
            <div className='mb-3'>
                <div className={toggleSidebar ? 'logo-toggle' : 'logo'} />
                <div className={toggleSidebar ? 'd-none' : 'w-100 d-flex justify-content-center'}>
                    <span className='text-white text-wrap text-center logo-caption' style={{ width: '15rem' }}>
                        Cordial Hotel
                    </span>
                </div>
            </div>
            <div className='fill-height'>
                <div className='h-100'>
                    <PanelMenu multiple model={items} className='overflowY-auto h-100' />
                </div>
            </div>
        </div>
    );
}
