/* eslint-disable no-empty-pattern */
import { ACTION_MODE, VARCHAR_MAX_LENGTH, VARCHAR_MIN_LENGTH } from 'app/constants';
import { bookingService, commonService } from 'app/services';
import roomService from 'app/services/room/room.service';
import validatorChain from 'app/utils/validator-chain';
import { HttpStatusCode } from 'axios';
import moment from 'moment';
import { InputText } from 'primereact';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const disabledDay = moment({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// eslint-disable-next-line react/prop-types
function AddEditBooking({ onChange }, refs) {
    const { t } = useTranslation();
    const [ID, setID] = useState({});
    const [mode, setMode] = useState(false);
    const [Name, setName] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Address, setAddress] = useState('');
    const [Adult, setAdult] = useState('0');
    const [Child, setChild] = useState('0');
    const [Arrival, setArrival] = useState('');
    const [Departure, setDeparture] = useState('');
    const [selectedRoom, setSelectedRoom] = useState(1);
    const [optionRoom, setOptionRoom] = useState([]);
    const [Note, setNote] = useState('');
    const [Qty, setQty] = useState(1);
    const [Total, setTotal] = useState(0);
    const [Offer, setOffer] = useState('');
    const [error, setError] = useState({});
    const [show, setShowModal] = useState(false);
    useImperativeHandle(refs, () => ({
        /**
         * show dialog edit/add user
         * @param {Object} data
         */
        show: (data) => {
            if (data.mode !== ACTION_MODE.ADD) {
                setID(data.ID);
                setName(data.Name);
                setPhone(data.Phone);
                setEmail(data.Email);
                setAddress(data.Address);
                setAdult(data.Adult);
                setChild(data.Child);
                setArrival(new Date(data.Arrival));
                setDeparture(new Date(data.Departure));
                setNote(data.Note);
                setOffer(data.detail.Offer);
                setQty(data.detail.Qty);
                setTotal(data.detail.Total);
                setSelectedRoom(data.RoomID);
            }
            setMode(data.mode);
            setShowModal(true);
        }
    }));
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async () => {
        const payload = {
            searchField: '',
            selectedCategory: [1, 2]
        };
        const data = await roomService.search(payload);
        setOptionRoom(data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.show();
        fetchData();
    }, []);
    /**
     * Reset data
     */
    const initData = () => {
        setName('');
        setPhone('');
        setEmail('');
        setAddress('');
        setAdult('');
        setChild('');
        setArrival('');
        setDeparture('');
        setNote('');
        setSelectedRoom(1);
        setError({});
    };
    /**
     * Validate data
     * @returns Boolean
     */
    const validateData = () => {
        const errors = {
            Name: validatorChain(Name.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Phone: validatorChain(Phone.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Email: validatorChain(Email.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Adult: validatorChain(Adult.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Child: validatorChain(Child.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError(),
            Arrival: validatorChain(Arrival).isRequire('M001').getError(),
            Departure: validatorChain(Departure).isRequire('M001').getError(),
            Address: validatorChain(Address.trim())
                .isRequire('M001')
                .isLength({ min: VARCHAR_MIN_LENGTH, max: VARCHAR_MAX_LENGTH }, 'M003')
                .getError()
        };
        setError(errors);
        return Object.values(errors).reduce((r, c) => (c === null ? r : false), true);
    };
    /**
     * Add user
     */
    const addBooking = async () => {
        if (validateData()) {
            const res = await bookingService.create({
                RoomID: selectedRoom,
                Name,
                Phone,
                Email,
                Adult,
                Child,
                Arrival,
                Departure,
                Address,
                Note
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M019')
                });
                setShowModal(false);
                initData();
                onChange(true);
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M009')
                });
            }
        }
    };
    /**
     * Update user
     */
    const editBooking = async () => {
        if (await validateData()) {
            const res = await bookingService.update({
                RoomID: selectedRoom,
                ID,
                Name,
                Phone,
                Email,
                Adult,
                Child,
                Arrival,
                Departure,
                Address
            });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M012')
                });
                setShowModal(false);
                initData();
                onChange(true);
            } else {
                commonService.toast.show({
                    severity: 'error',
                    summary: t('ERROR_MESSAGE'),
                    detail: t('M011')
                });
            }
        }
    };
    /**
     * Hide dialog
     */
    const onCancel = async () => {
        initData();
        setShowModal(false);
    };
    return (
        <Modal show={show} centered size='xl' backdrop='static' className={mode ? 'modal-edit-error' : 'modal-edit-error read-input'}>
            <Modal.Body className='py-0 px-3'>
                <h2 className='text-primary text-center fw-bold pt-4 fs-24'>
                    {mode === ACTION_MODE.ADD
                        ? t('BTN_ADD_BOOKING')
                        : mode === ACTION_MODE.EDIT
                          ? t('LABEL_EDIT_BOOKING')
                          : t('LABEL_DETAIL_BOOKING')}
                </h2>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 pt-5 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_NAME')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Name'
                                value={Name}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_NAME')}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 pt-5 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ADDRESS')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Address'
                                value={Address}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_ADDRESS')}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Name'])}</label>
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Address'])}</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_PHONE_NUMBER')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Phone'
                                value={Phone}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_PHONE')}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_EMAIL')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Email'
                                value={Email}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_EMAIL')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Phone'])}</label>
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Email'])}</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ADULT')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Adult'
                                value={Adult}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_ADULT')}
                                onChange={(e) => setAdult(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_CHILD')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputText
                                id='Child'
                                value={Child}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md'
                                placeholder={t('PLACEHOLDER_ENTER_CHILD')}
                                onChange={(e) => setChild(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Adult'])}</label>
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Child'])}</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ARRIVAL')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <Calendar
                                value={Arrival}
                                onChange={(e) => {
                                    setArrival(e.target.value);
                                }}
                                baseZIndex={1055}
                                maxDate={disabledDay.toDate()}
                                inputClassName='p-calendar-input fs-md'
                                placeholder={t('PLACEHOLDER_SELECT_DATE')}
                                showButtonBar
                                disabled={mode === ACTION_MODE.DETAIL}
                                showIcon
                                appendTo='self'
                                selectionMode='single'
                                className='w-100 fs-md'
                                icon='icon calendar'
                                readOnlyInput={true}
                                clearButtonClassName='border btn-cancel-sm rounded btn btn-size-sm calendar-btn fs-md btn-w-100'
                                todayButtonClassName='border btn-action-sm rounded btn btn-size-sm calendar-btn fs-md text-white border-0 btn-w-100'
                            />
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_DEPARTURE')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <Calendar
                                value={Departure}
                                onChange={(e) => {
                                    setDeparture(e.target.value);
                                }}
                                baseZIndex={1055}
                                maxDate={disabledDay.toDate()}
                                inputClassName='p-calendar-input fs-md'
                                placeholder={t('PLACEHOLDER_SELECT_DATE')}
                                showButtonBar
                                appendTo='self'
                                disabled={mode === ACTION_MODE.DETAIL}
                                showIcon
                                selectionMode='single'
                                className='w-100 fs-md'
                                icon='icon calendar'
                                readOnlyInput={true}
                                clearButtonClassName='border btn-cancel-sm rounded btn btn-size-sm calendar-btn fs-md btn-w-100'
                                todayButtonClassName='border btn-action-sm rounded btn btn-size-sm calendar-btn fs-md text-white border-0 btn-w-100'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Arrival'])}</label>
                        </div>
                    </div>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'></div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <label className='break-work text-danger fs-md'>{t(error['Departure'])}</label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 row py-2 mx-0 px-4 centered-control'>
                        <div className='col-3 ps-0'>
                            <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_NOTE')}</label>
                        </div>
                        <div className='d-flex flex-column col-9 px-0'>
                            <InputTextarea
                                id='Note'
                                value={Note}
                                disabled={ACTION_MODE.DETAIL === mode}
                                className='px-2 fs-md w-100'
                                placeholder={t('PLACEHOLDER_ENTER_NOTE')}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='col-6 row pt-2 pb-3 mx-0 px-4 centered-control'>
                        <div className='row px-0 mx-0'>
                            <div className='col-3 ps-0'>
                                <label className='fs-md w-100 ps-3'>{t('TABLE_LABEL_ROOM_NAME')}</label>
                            </div>
                            <div className='d-flex flex-column col-9 px-0'>
                                <Dropdown
                                    className='w-100 fs-md multi-select-systems'
                                    value={selectedRoom}
                                    options={optionRoom}
                                    optionLabel='Name'
                                    disabled={ACTION_MODE.DETAIL === mode}
                                    appendTo='self'
                                    optionValue='ID'
                                    onChange={(e) => {
                                        setSelectedRoom(e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='row mx-0 px-0 py-2'>
                            <div className='col-3 ps-0'>
                                <label className='fs-md w-100 ps-3'>{t('LABEL_NUMBER_OF_ROOM')}</label>
                            </div>
                            <div className='d-flex flex-column col-9 px-0'>
                                <InputNumber
                                    value={Qty}
                                    onValueChange={(e) => setQty(e.value)}
                                    mode='decimal'
                                    showButtons
                                    disabled
                                    min={1}
                                    max={10}
                                />
                            </div>
                        </div>
                        <div className='row mx-0 px-0 py-2'>
                            <div className='col-3 ps-0'>
                                <label className='fs-md w-100 ps-3'>{t('LABEL_DISCOUNT')}</label>
                            </div>
                            <div className='d-flex flex-column col-9 px-0'>
                                <InputText value={Offer} disabled />
                            </div>
                        </div>
                        <div className='row mx-0 px-0 py-2'>
                            <div className='col-3 ps-0'>
                                <label className='fs-md w-100 ps-3'>{t('LABEL_TOTAL')}</label>
                            </div>
                            <div className='d-flex flex-column col-9 px-0'>
                                <InputText value={Total} disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2 py-4 border-top'>
                    {mode !== ACTION_MODE.DETAIL && (
                        <Button className='btn-w-120 rounded border btn-cancel-sm' variant='default' onClick={onCancel}>
                            {t('BTN_CANCEL')}
                        </Button>
                    )}
                    <Button
                        className='btn-w-120 rounded text-white fw-bold btn-action-sm'
                        variant='default'
                        onClick={mode === ACTION_MODE.DETAIL ? onCancel : mode === ACTION_MODE.ADD ? addBooking : editBooking}>
                        {t('BTN_OK')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default memo(forwardRef(AddEditBooking));
