import { BOOKING_CONFIRM_URL, BOOKING_DETAIL_URL, BOOKING_URL, DEFAULT_SORT_DIR, SORT_ORDER } from 'app/constants';
import { requestGet, requestPost, requestPut } from 'app/utils/request';
import { HttpStatusCode } from 'axios';
const create = (data) => requestPost(BOOKING_URL, data);
const search = async (param) => {
    const params = {
        ...param,
        sortOrder: param.sortOrder === DEFAULT_SORT_DIR ? SORT_ORDER.DESC : SORT_ORDER.ASC
    };
    const result = await requestGet(BOOKING_URL, { params });
    const { status, data } = result;
    if (status === HttpStatusCode.Ok) {
        return data.payload;
    }
    return { data: [], total: 0 };
};
const update = (data) => requestPut(BOOKING_URL, data);
const confirm = (data) => requestPost(BOOKING_CONFIRM_URL, data);
const detail = async (params) => requestGet(BOOKING_DETAIL_URL, { params });
export default {
    create,
    search,
    confirm,
    update,
    detail
};
