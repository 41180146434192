// Adapter Base64 cho CKEditor
class Base64UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({ default: reader.result }); // Base64 string
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                })
        );
    }

    abort() {
        // TODO
    }
}

export default Base64UploadAdapter;
