import { HOTEL, ROUTER } from 'app/constants';
import { commonService } from 'app/services';
import roomService from 'app/services/room/room.service';
import { useRedirect } from 'app/utils/browser';
import { isDirty } from 'app/utils/until';
import MultiSelectModify from 'app/views/common/multi-select';
import { HttpStatusCode } from 'axios';
import _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
export default function RoomList() {
    const { t } = useTranslation();
    const inputSearch = useRef();
    const [selectedCategory, setSelectedCategory] = useState(HOTEL.map((v) => v.value));
    const [searchField, setSearchFieldTyping] = useState('');
    const [oldFilter, setOldFilter] = useState(HOTEL.map((v) => v.value));
    const [data, setData] = useState([]);
    const redirect = useRedirect();
    const [breakfast, setValueBreakfast] = useState([]);
    /**
     * fetch data from api
     * @param {Object} input
     * @returns
     */
    const fetchData = async () => {
        commonService.preloader.show();
        const data = await roomService.search({ selectedCategory, searchField });
        const res = data.map((v) => {
            return { _id: v.ID, check: false, price: v.Price.Norm, priceSale: v.Price.Sale };
        });
        setValueBreakfast(res);
        setData(data);
        commonService.preloader.hide();
        return true;
    };
    /**
     * Update user permission
     * @param {String} _id
     * @param {Number} permission
     */
    const onChange = (_id, permission, price) => {
        const temp = _.keyBy(breakfast, '_id');
        // update data in current table
        temp[_id].check = permission;
        temp[_id].percent = permission;
        if (!_.isEmpty(price)) {
            temp[_id].price = price.Norm;
            temp[_id].priceSale = price.Sale;
        }
        setValueBreakfast(Object.values(temp));
    };
    /**
     * re-load table and execute function to search data
     * @function fetchData Handle and display data on table
     */
    const onSearch = (e) => {
        e.preventDefault();
        fetchData();
    };
    /**
     * On hide filter
     */
    const onHideFilter = async () => {
        if (isDirty(_.sortBy(oldFilter), _.sortBy(selectedCategory)) || selectedCategory.length !== oldFilter.length) {
            await fetchData();
            setOldFilter(selectedCategory);
        }
    };
    /**
     * Handle Delete Action
     * @param {Number} ID ID action
     */
    const remove = async (ID) => {
        const result = await commonService.modal.showConfirm({
            header: t('LABEL_DELETE_ITEM'),
            message: t('M016'),
            img: 'delete-message',
            customClass: 'warning-confirm-delete px-0'
        });
        if (result) {
            commonService.preloader.show();
            const res = await roomService.remove({ ID });
            if (res.status === HttpStatusCode.Ok) {
                commonService.toast.show({
                    severity: 'success',
                    summary: t('INFO_MESSAGE'),
                    detail: t('M018')
                });
                fetchData();
                commonService.preloader.hide();
                return true;
            }
            commonService.preloader.hide();
            return commonService.toast.show({
                severity: 'error',
                summary: t('ERROR_MESSAGE'),
                detail: t('M017')
            });
        }
    };
    /**
     * Fetch first render data
     */
    useEffect(() => {
        commonService.preloader.hide();
        fetchData();
        inputSearch.current.focus();
    }, []);
    const rooms = (type) => {
        const result = !type ? data : data.filter((v) => v.Category === type);
        const list = [];
        const mapBreakfast = _.keyBy(breakfast, '_id');
        for (const item of result) {
            const checkBreakfast = mapBreakfast[item.ID];
            const percent = Math.round(100 - (checkBreakfast.priceSale * 100) / checkBreakfast.price);
            const value = (
                <div className='col-lg-4 room-block-two py-3' key={item.ID}>
                    <div className='inner-box pb-4'>
                        <div className='image'>
                            <img src={`${process.env.PUBLIC_URL}/image/room/${item.ID}/${item.ImageMain}`} alt='' />
                            <div className='sale-off'>-{percent}%</div>
                            <div className='d-flex justify-content-center'>
                                <div className='price'>
                                    <div className={_.isEmpty(item.PriceBreakFast) ? 'd-none' : 'row mx-3'}>
                                        <div className='col-6'>
                                            <RadioButton
                                                inputId={`noBreakfast` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, false, item.Price)}
                                                checked={!checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`noBreakfast` + item.ID} className='px-2 fs-md'>
                                                No Breakfast
                                            </label>
                                        </div>
                                        <div className='col-6'>
                                            <RadioButton
                                                inputId={`haveBreakfast_` + item.ID}
                                                name='breakfast'
                                                onChange={() => onChange(item.ID, true, item.PriceBreakFast)}
                                                checked={checkBreakfast?.check || false}
                                            />
                                            <label htmlFor={`haveBreakfast_` + item.ID} className='px-2 fs-md'>
                                                Have Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <div className='row p-2'>
                                        <div className='col-md-6 price-original'>
                                            {Number(checkBreakfast?.price || 0).toLocaleString('en')} VND
                                        </div>
                                        <div className='col-md-6 price-sale'>
                                            {Number(checkBreakfast?.priceSale || 0).toLocaleString('en')} VND
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-3'>
                            <h3 className='blog-page-title'>
                                <a
                                    href='/#'
                                    className='fp-24 text-ellipsis-1line'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.ROOM_DETAIL), ROUTER.ID, { id: item.ID }, item.ID);
                                        window.location.reload();
                                    }}>
                                    {item.Name}
                                </a>
                            </h3>
                            <div className='icon-list'>
                                <ul className='row px-1'>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon bed mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.bed}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon blueprint mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>
                                            {item.RoomAmenities.main.blueprint}
                                            <span className='px-1'>
                                                m<sup>2</sup>
                                            </span>
                                        </span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon direction mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.direction}</span>
                                    </li>
                                    <li className='d-flex align-items-center col-6 py-2'>
                                        <span className='icon people mx-0'></span>
                                        <span className='px-2 fs-md text-ellipsis-1line'>{item.RoomAmenities.main.people}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='text-two text-ellipsis-2line mh-56'>{item.SpecialDescription}</div>
                            <div className='row mx-0'>
                                <Button
                                    className='btn-cancel-sm w-80px'
                                    variant='default'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.EDIT_ROOM), ROUTER.ID, { id: item.ID }, item.ID);
                                        window.location.reload();
                                    }}>
                                    Edit
                                </Button>
                                <Button
                                    className='btn-cancel-sm w-80px mx-2'
                                    variant='default'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(generatePath(ROUTER.ROOM_IMAGE), ROUTER.ID, { id: item.ID }, item.ID);
                                        window.location.reload();
                                    }}>
                                    Image
                                </Button>
                                <Button className='btn-action-sm w-80px' variant='default' onClick={() => remove(item.ID)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
            list.push(value);
        }
        return <div className='position-relative row mx-0'>{list}</div>;
    };
    return (
        <React.Fragment>
            <div className='page-table d-flex flex-column fill-height table-common px-3'>
                <div className='d-flex flex-column fill-height px-0 pt-2'>
                    <div className='h-100 d-flex flex-column rounded'>
                        <div className='col-12 row mx-0 py-2'>
                            <div className='w-30 py-2 row mx-0 px-0'>
                                <h1 className='my-0 fs-xxl fw-bold text-primary w-100 px-0 align-items-center d-flex'>{t('LABEL_ROOM')}</h1>
                            </div>
                            <div className='w-20 px-0 h-40-px'>
                                <form onSubmit={onSearch} className='position-relative width-input' autoComplete='off'>
                                    <InputText
                                        placeholder={t('PLACEHOLDER_ENTER_KEY_SEARCH')}
                                        className='ps-3 w-100'
                                        value={searchField}
                                        ref={inputSearch}
                                        onChange={(e) => setSearchFieldTyping(e.target.value)}
                                    />
                                    <Button variant='custom-inline' type='submit'>
                                        <span className='icon search fs-md icon-search'></span>
                                    </Button>
                                </form>
                            </div>
                            <div className='w-20 px-0'>
                                <MultiSelectModify
                                    className='w-100 fs-md ms-3 multi-select-systems'
                                    value={selectedCategory}
                                    labelMaxSelectedItem={t('USER_MANAGEMENT_LABEL_ALL_HOTEL')}
                                    options={HOTEL}
                                    onChange={(e) => {
                                        setSelectedCategory(e.value);
                                    }}
                                    onHide={() => {
                                        onHideFilter();
                                    }}
                                />
                            </div>
                            <div className='w-10 px-0 d-flex justify-content-end'>
                                <a href={ROUTER.ADD_ROOM} className='btn-action-sm px-4'>
                                    {t('BTN_ADD_ROOM')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 h-80 overflow-auto'>{rooms()}</div>
        </React.Fragment>
    );
}
